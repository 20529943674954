<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Color</h1>
          <v-form ref="investmentForm">
            <div>
              <v-label>Color Name</v-label>
              <v-text-field
                v-model="colorName"
                outlined
                placeholder="Color Name"
                class="mt-1"
              ></v-text-field>
            </div>
            <v-btn @click="validate" v-if="!loading" large>Add Color</v-btn>
            <v-btn v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddColor",
  data: () => ({
    colorName: undefined,
    loading: false,
    limit: 100,
    page: 1,
    colors: [],
  }),
  methods: {
    ...mapActions(["postColor", "getSingleColor"]),
    async validate() {
      this.loading = true;
      let formData = {};
      if (this.colorName != undefined) {
        formData.colorName =  this.colorName;
      }


      if (this.$route.params.id != undefined) {
        formData._id =  this.$route.params.id;
      }

      await this.postColor(formData);
      if (this.allColor.status == 200 || this.allColor.status == 201) {
        this.$router.push("/business/colors");
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allColor"]),
  },
  async created() {
    if (this.$route.params.id) {
      await this.getSingleColor(this.$route.params.id);
      this.colorName = this.allColor.results.colorName;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  display: flex;
  flex-flow: column;
  img {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>